import React , { useCallback , useState , useEffect } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { useGlobalContext } from '../../../context/GlobalContext';
import { getCalendarAction , postCalendarAction } from '../../../actions/formActions';

import Button from '../../partials/Button/Button';

import meetImg from '../../../assets/images/meet.png';

import styles from './Meet.module.scss';
import SelectMeet from '../../partials/SelectMeet/SelectMeet';
import Loader from '../../partials/Loader/Loader';

export default function Meet() {

  const [ context , dispatch ] = useGlobalContext();
  const [ selectedMeet , setSeletedMeet ] = useState();
  const [ isLoading , setIsLoading ] = useState(true);  

  const { calendar , meetDate , quote } = context.form ;
  const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']

  const history = useHistory();

  const getCalendar = useCallback(() => {
    getCalendarAction(dispatch);
    },[dispatch]);

  useEffect(() => {
    getCalendar();
  }, [getCalendar]);

  useEffect(() => {
    if (meetDate) {
      history.push('/end');
    }
    // eslint-disable-next-line
  },[meetDate]);

  useEffect(() => {
    if (calendar && calendar.length === 0) {
      history.push('/end');
    }

    if (calendar && calendar.length > 0) {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  },[calendar]);

  const submitMeet = () => {
    let meetData = {
      event: selectedMeet.id,
      user: selectedMeet.user,
      quote: quote._id
    }
    postCalendarAction(dispatch , meetData , selectedMeet.date );
    setIsLoading(true);
  }



  return (
    <div className={`page-content`}>
      <div className="content">
        <div className="row">

          <div className="col-2">
            <div className={`${styles.container} ${styles.anim}`}>
            <div>
              <h2>Je choisis quand je veux être rappelé</h2>
              {isLoading &&
                <div className={styles.loader}>
                  <Loader size={40} isLoading={true} color={'#4AC3C9'}/>
                </div>
              }
              {!isLoading && calendar &&
                <SelectMeet calendar={calendar} selectedMeet={selectedMeet} setSeletedMeet={setSeletedMeet} days={days}/> 
              }

            </div>

            <div className={styles.meet}>
            {selectedMeet?.date && <p className={`${styles.anim} bold`}>Vous avez choisi<br/>
              le {days[selectedMeet.date.getDay()]} {format( new Date(selectedMeet.date), 'dd/MM/yyyy')} à {format(new Date(selectedMeet.date), 'HH:mm')}</p>}
            </div>

            <div className={styles.buttons}>
              <Button
                disabled={selectedMeet ? false : true }
                onClick={() => submitMeet()}
                primary
              >
                <div className={styles.icon}> VALIDER </div>
              </Button>
            </div>

            </div>
          </div>

          <div className="col-2 illu">
            <img className="anim-bottom" src={meetImg} alt="meet" />
          </div>

        </div>
      </div>
    </div>
  );
}