import React from 'react';
import { Link } from 'react-router-dom';
import home from '../../../assets/images/home.png';
import Button from '../../partials/Button/Button';
import styles from './Home.module.scss';

export default function Home() {
  return (
    <div className={`page-content ${styles.container}`}>
      <div className="content">
        <div className="row">

          <div className={`col-2 ${styles.anim}`}>
            <h1> Faire ZEDEVIS</h1>
              <p>
                Obtenez l’estimation des honoraires mensuels en quelques clics<br/>par le biais de ce parcours intuitif et digital
              </p>
            <div>
              <Link to="/activity">
              <Button primary>Demande de devis</Button>
              </Link>
            </div>
          </div>

          <div className={`col-2 anim ${styles.illu}`}>
            <img src={home} alt="home" />
          </div>

        </div>
      </div>
    </div>
  )
}
