import React from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import { SITE_URL } from '../../../actions/index';
import logo from '../../../assets/images/logo-xs-blue.svg';
import logoXl from '../../../assets/images/logo-xl-blue.svg';
import styles from './Header.module.scss';

export default function Header() {
  return (
    <div className={styles.container}>
      <a href={SITE_URL}>
        <img src={logo} alt="logo"/>
        <HiArrowLeft size={24} />
        <p>Retour au site</p>
      </a>
      <img src={logoXl} alt="logo" />
    </div>
  )
}
