import React, { useEffect , useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../context/GlobalContext';

import home from '../../../assets/images/home.png';
import Step from '../../partials/Step/Step';

export default function Step1() {

  const [ context , dispatch ] = useGlobalContext();
  const { quoteForm , countSteps } = context.form;
  const [ activeStep , setActiveStep ] = useState();

  const { activity } = useParams();

  const step = {
    prev: "/activity",
  } 

  let questions = [
    {
      question: "Quelques petites précisions...",
      key: "subActivity",
      type: "list",
      activities : ['location'],
      options: [
        { label: "Meublée", value: "furnished" },
        { label: "Non meublée", value: "unfurnished"},
        { label: "Je ne sais pas", value: "unknown"},
      ]
    },
    {
      question: "Vous exercez en...",
      key: "type",
      type: "list",
      activities : ['location' , 'liberal' , 'freelance' , 'artisan' ],
      options: [
        { label: "Société", value: "company" },
        { label: "Entreprise individuelle", value: "individual"},
        { label: "Je ne sais pas", value: "unknown"},
      ]
    },
    {
      question: "Votre organisation administrative",
      key: "administrative",
      type: "list",
      activities : ['artisan'],
      options: [
        { label: "Caisse électronique", value: "electronic" },
        { label: "Caisse manuelle", value: "manual"},
        { label: "Factures", value: "invoice" },
        { label: "Ne sais pas", value: "unknown" },
      ]
    },
    {
      question: "Chiffre d'affaires/recettes",
      key: "turnover",
      type: "list",
      activities : ['liberal' , 'freelance' , 'artisan' , 'entrepreneur' ],
      options: [
        { label: "< 35 000 € par an", value: "lessThan35000" },
        { label: "Entre 35 et 100 000 € / an", value: "between35000and100000"},
        { label: "Préciser CA si plus de 100 000€/ an", value: "other" },
        // { label: "préciser CA si plus de 100 000 €", value: "other" },
      ]
    },
    {
      question: "Si choix SOCIETE : Elle est soumise à Impôt sur les sociétés ?",
      key: "isTaxed",
      type: "list",
      activities : ['company'],
      options: [
        { label: "Oui", value: "yes" },
        { label: "Non", value: "no"},
        { label: "Je ne sais pas", value: "unknown"},
      ]
    },
    {
      question: "Préciser votre chiffre d'affaires (en euro)",
      key: "turnoverValue",
      type: "number",
      activities : ['turnoverValue'],
    },
   
  ];

  useEffect(() => {
    if (activity) {
      switch (activity) {

        case 'location':
          step.title = "Activité de location immobilière" ;
          step.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in laoreet diam. Maecenas sollicitudin enim vitae massa lobortis.";
          step.questions = questions.filter(q => q.activities.find( activity => activity === 'location'));
          step.totalSteps = 6 ;
          return setActiveStep(step);

        case 'liberal':
          step.title = "Activité libérale" ;
          step.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in laoreet diam. Maecenas sollicitudin enim vitae massa lobortis.";
          step.questions = questions.filter(q => q.activities.find( activity => activity === 'liberal'));
          step.totalSteps = 5 ;
          return setActiveStep(step);

        case 'freelance':
          step.title = "Activité indépendante" ;
          step.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in laoreet diam. Maecenas sollicitudin enim vitae massa lobortis.";
          step.questions = questions.filter(q => q.activities.find( activity => activity === 'freelance'));
          step.totalSteps = 5 ;
          return setActiveStep(step);

        case 'artisan':
          step.title = "Activité artisanale" ;
          step.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in laoreet diam. Maecenas sollicitudin enim vitae massa lobortis.";
          step.questions = questions.filter(q => q.activities.find( activity => activity === 'artisan'));
          step.totalSteps = 6 ;
          return setActiveStep(step);
        
        case 'entrepreneur':
          step.title = "Activité entrepreneur" ;
          step.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in laoreet diam. Maecenas sollicitudin enim vitae massa lobortis.";
          step.questions = questions.filter(q => q.activities.find( activity => activity === 'entrepreneur'));
          step.totalSteps = 4 ;
          return setActiveStep(step);
      
        default:
          return null;
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activeStep && quoteForm) {
      let updatedStep = Object.assign({} , activeStep);

      if (quoteForm.type === "company") {
        updatedStep.questions = questions.filter(q => q.activities.find( item => item === activity || item === 'company' ));
        updatedStep.totalSteps = updatedStep.questions.length + 1;
        setActiveStep(updatedStep);
        dispatch({
          type: 'SET_COUNT_STEPS',
          payload: countSteps - 1
        });
      } else {
        updatedStep.questions = questions.filter(q => q.activities.find( item => item === activity ));
        updatedStep.totalSteps = updatedStep.questions.length;
        if (quoteForm.isTaxed) {
          let updatedForm = Object.assign({} , quoteForm );
          delete updatedForm.isTaxed
          dispatch({
            type: 'SET_FORM',
            payload: updatedForm
          });
          dispatch({
            type: 'SET_COUNT_STEPS',
            payload: countSteps - 1
          });
        };
        setActiveStep(updatedStep);
      }

      if (quoteForm.turnover) {
        if ( quoteForm.turnover === "other" ) {
          updatedStep.questions = questions.filter(q => q.activities.find( item => item === activity || item === 'turnoverValue' ));
          let total = 6 ;
          if (activity === 'artisan') total = 7 ;
          if (activity === 'entrepreneur') total = 5
          updatedStep.totalSteps = total ;
          if (!quoteForm.turnoverValue) {
            dispatch({
              type: 'SET_COUNT_STEPS',
              payload: total - 1
            });
          }
          setActiveStep(updatedStep);
        } else {
          updatedStep.questions = questions.filter(q => q.activities.find( item => item === activity ));
          let total = 5 ;
          if (activity === 'artisan') total = 6 ;
          if (activity === 'entrepreneur') total = 4
          updatedStep.totalSteps = total ;

          dispatch({
            type: 'SET_COUNT_STEPS',
            payload: total 
          });

          if (quoteForm.turnoverValue) {
            let updatedForm = {...quoteForm};
            updatedForm.turnoverValue = null;
            dispatch({
              type: 'SET_FORM',
              payload: updatedForm
            });
          }
          setActiveStep(updatedStep);
        }
      }
    }
    // eslint-disable-next-line
  },[quoteForm])

  return (
    <div className={`page-content`}>
      <div className="content">
        <div className="row">

          <div className="col-2">
            {activeStep &&
              <Step
                step={activeStep}
                next={`/step2/${activity}`}
                />
            }
          </div>

          <div className="col-2 illu anim">
            <img src={home} alt="home" />
          </div>

        </div>
      </div>
    </div>
  )
}