import React , { useEffect , useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';

import puzzle from '../../../assets/images/puzzle.png';
import Step from '../../partials/Step/Step';

export default function Activity() {

  const initStep = {
    title: "Qui êtes-vous ?",
    text: "",
    prev: "/",
    totalSteps: 2,
    questions: [
      {
        question: "Votre situation...",
        key: "launchState",
        type: "list",
        options: [
          { label: "Je me lance !", value: "launching" },
          { label: "Mon activité est lancée", value: "launched"},
        ]
      },
      {
        question: "Quelle est votre activité ?",
        key: "activity",
        type: "select",
        options: [
          { label: "Activité de location immobilière", value: "location" },
          { label: "Profession libérale", value: "liberal"},
          { label: "Prestataire de services , Freelance, Consultant", value: "freelance"},
          { label: "Artisan , Commerçant", value: "artisan"},
          { label: "Micro entrepreneur", value: "entrepreneur"},
          { label: "Autres", value: "other"},
        ]
      }
    ]
  }

  const context = useGlobalContext()[0];
  const { quoteForm } = context.form;
  const [ step , setStep ] = useState(initStep);
  
  useEffect(() => {
    if (quoteForm.launchState === "launching") {
      let updatedStep = Object.assign({} , initStep );
      updatedStep.totalSteps = 3;
      updatedStep.questions.splice(1, 0, {
        question: "Souhaitez-vous un accompagnement à la création de votre entreprise ?",
        key: "needAccompaniment",
        type: "list",
        options: [
          { label: "Oui", value: true },
          { label: "Non", value: false },
        ]
      });
      setStep(updatedStep);
    } else {
      setStep(initStep);
    }
    //eslint-disable-next-line
  }, [quoteForm?.launchState])

  return (
    <div className={`page-content`}>
      <div className="content">
        <div className="row">

          <div className="col-2">
            <Step
              step={step}
              next={quoteForm && quoteForm.activity ? quoteForm.activity !== 'other' ? `/step1/${quoteForm.activity}`: `/valid/${quoteForm.activity}` : null }
              />
          </div>

          <div className="col-2 illu anim">
            <img src={puzzle} className="col-2 puzzle" alt="puzzle" />
          </div>

        </div>
      </div>
    </div>
  )
}