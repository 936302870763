import React, { useEffect , useCallback } from "react";
import { Switch, Route , useHistory } from "react-router-dom";
import { useGlobalContext } from '../context/GlobalContext';

import { setVhUnit } from '../utils/utils';
import Account from "./pages/Account/Account";
import Activity from "./pages/Activity/Activity";
import End from "./pages/End/End";
import Home from "./pages/Home/Home";
import Meet from "./pages/Meet/Meet";
import Step1 from "./pages/Step1/Step1";
import Step2 from "./pages/Step2/Step2";
import User from "./pages/User/User";
import Valid from "./pages/Valid/Valid";
import Header from "./partials/Header/Header";

export default function GlobalRoutes() {

  const history = useHistory();

  const context = useGlobalContext()[0];
  const { countSteps } = context.form;

  const init = useCallback(() => {
    setVhUnit();
  },[])

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (countSteps === 0 ) {
      history.push("/")
    }
    // eslint-disable-next-line
  },[countSteps])


  return (
    <div className="page-container">
      <Header/>
      <Switch>

        <Route exact path={"/"} render={
          (props) => <Home {...props}/>
        }/>

        <Route path={"/activity"} render={
          (props) => <Activity  {...props}/>
        }/>

        <Route path={"/step1/:activity"} render={
          (props) => <Step1 {...props}/>
        }/>

        <Route path={"/step2/:activity"} render={
          (props) => <Step2 {...props}/>
        }/>

        <Route path={"/user/:activity"} render={
          (props) => <User {...props}/>
        }/>

        <Route path={"/valid/:activity"} render={
          (props) => <Valid {...props}/>
        }/>

        <Route path={"/meet/:activity"} render={
          (props) => <Meet {...props}/>
        }/>

        <Route path={"/end"} render={
          (props) => <End {...props}/>
        }/>

        <Route path={"/account"} render={
          (props) => <Account {...props}/>
        }/>

      </Switch>
    </div>
  )
}
