import React , { useState } from 'react';
import { useParams , Link , useHistory } from 'react-router-dom';
import { HiArrowLeft ,  HiArrowRight  } from 'react-icons/hi';

import Button from '../../partials/Button/Button';
import CheckBox from '../../partials/Form/CheckBox/CheckBox';

import validImg from '../../../assets/images/valid.png';

import styles from './Valid.module.scss';

export default function Valid() {

  const { activity } = useParams();
  const history = useHistory();

  const [ accepted , setAccepted ] = useState({
    checked1 : false,
    checked2 : false,
  });

  return (
    <div className={`page-content`}>
      <div className="content">
        <div className="row">

          <div className="col-2">
            <div className={`${styles.container} ${styles.anim}`}>
            <div>
              <h2>Synthèse</h2>

              <p>Le devis qui va m’être présenté sera formalisé sur la base de mes déclarations et choix de prestations selon les besoins et conditions d'activité que j’ai estimé.</p>

              <p>Ce devis est gratuit et vaut demande de service auprès de la société.</p>

              <p>A réception de ma demande, le cabinet d’expertise-comptable me contactera pour évoquer avec moi les prestations que j’ai choisies.</p>
              <CheckBox
                label={"Je certifie être informé"}
                defaultValue={accepted.checked1}
                onChange={(val) => setAccepted({...accepted , checked1: val })}
                />

              <p>En fonction de l'appréciation par l'expert comptable de ma situation, de mes besoins et de mon activité, ces prestations pourront éventuellement être modifiées avec mon accord.</p>

              <p>La mission de l'expert-comptable pourra commencer sans délai après signature par mes soins de la lettre de mission, ce qui constituera mon contrat.</p>
              <CheckBox
                label={"Je certifie être informé"}
                defaultValue={accepted.checked2}
                onChange={(val) => setAccepted({...accepted , checked2: val })}
                />

            </div>

            <div className={styles.buttons}>
              <Link to={`/step2/${activity}`}>
                <Button
                  primary
                >
                  <div className={styles.icon}> <HiArrowLeft size={18} /> PRÉCÉDENT </div>
                </Button>
              </Link>
              <Button
                type={"submit"}
                onClick={() => history.push(`/user/${activity}`)}
                disabled={accepted.checked1 && accepted.checked2 ? false : true }
                primary
              >
                <div className={styles.icon}> SUIVANT  <HiArrowRight size={18} /></div>
              </Button>
            </div>

            </div>
          </div>

          <div className="col-2 illu">
            <img className="anim-bottom" src={validImg} alt="valid" />
          </div>

        </div>
      </div>
    </div>
  )
}