import React from 'react';

import { ADMIN_URL } from '../../../actions/index'; 

import Button from '../../partials/Button/Button';

import quoteImg from '../../../assets/images/quote.png';
import styles from './Account.module.scss'

export default function Account() {

  return (
    <div className={`page-content`}>
      <div className="content">
        <div className="row">

          <div className="col-2">
            <div className={styles.container}>
              <div className={`${styles.message} ${styles.anim}`}>
                <img className="mobile" src={quoteImg} alt="home" /> 
                <h1>Merci pour votre confiance !</h1>
                <p>Vous disposez déjà d'un compte.<br/>Connectez-vous pour suivre vos devis</p>
                <div className={styles.connect}>
                  <a href={ADMIN_URL}>
                    <Button primary >
                      Me connecter
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-2 illu">
            <img className="anim-bottom" src={quoteImg} alt="end" />
          </div>

        </div>
      </div>
    </div>
  )
}