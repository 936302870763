import React from 'react';
// import { createBrowserHistory } from "history";
import { BrowserRouter as Router } from 'react-router-dom'

import { GlobalProvider } from './context/GlobalContext';

import formReducer from './reducers/formReducer';

import GlobalRoutes from './components/GlobalRoutes';

function App() {

  function combineReducers(reducers) {
    const reducerKeys = Object.keys(reducers);
  
    return function combination(state = {}, action) {
      const nextState = {}
      for (let i = 0; i < reducerKeys.length; i++) {
        const key = reducerKeys[i];
        const reducer = reducers[key]
        const previousStateForKey = state[key]
        const nextStateForKey = reducer(previousStateForKey, action)
        nextState[key] = nextStateForKey;
      }
      return nextState;
    }
  }

  const reducers = combineReducers({
    form: formReducer
  });

  const initialState = reducers( {} , { type: '__INIT__' });

  return (
    <GlobalProvider
      reducers={reducers}
      initialState={initialState}
      >
      <div className="app-container">
        <Router>
          <GlobalRoutes />
        </Router>
      </div>
    </GlobalProvider>
  );
}

export default App;