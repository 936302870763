export const   //FORM ACTIONS
  MESSAGE_FORM = 'MESSAGE_FORM',
  ERROR_FORM = 'ERROR_FORM',
  SET_FORM = 'SET_FORM',
  POST_USER = 'POST_USER',
  POST_CASE  = 'POST_CASE',
  POST_ENTITY  = 'POST_ENTITY',
  POST_QUOTE = 'POST_QUOTE',
  SET_USER_FORM = 'SET_USER_FORM',
  SET_COUNT_STEPS = 'SET_COUNT_STEPS',
  POST_CALENDAR = 'POST_CALENDAR',
  GET_CALENDAR = 'GET_CALENDAR';