import React from 'react';
import styles from './Button.module.scss';

export default function Button({ onClick , type,  children , disabled , className , primary , danger , outline , icon, radius }) {

  return (
    <button
      type={type ? type : "button"}
      onClick={onClick? () => onClick() : null }
      disabled={disabled}
      className={`
        ${styles.btn}
        ${icon ? styles.icon : ''}
        ${primary ? styles.primary : ''}
        ${danger ? styles.danger : ''}
        ${outline ? styles.outline : ''}
        ${radius ? styles.radius : ''}
        ${disabled ? styles.disabled : ''}
        ${className ? className : ''}`}
      >
      {children}
    </button>
  )
}
