import React from 'react';
import styles from './Input.module.scss';

export default function Input({ onChange , name , label , defaultValue , min , value , withErrors , error , register , placehloder , type , ...rest }) {
  return (
    <div className={styles.input}>
      <label htmlFor={name}>{label}</label>
      {!register ? 
        <>
          <input
            name={name}
            type={type}
            defaultValue={defaultValue || defaultValue === 0  ? defaultValue : ''}
            placeholder={placehloder}
            min={min ? min : null }
            value={value || value === 0 ? value : ''}
            onChange={(e) => onChange(e.target.value)}
            />
        </>
        :
        <>
          <input
            name={name}
            type={type}
            min={min ? min : null }
            className={error ? styles.error : ''}
            placeholder={placehloder}
            defaultValue={defaultValue ? defaultValue : ''}
            ref={register}
            {...rest}
            />
        </>
      }
      {withErrors && <p className={styles['error-message']}>{error && error}</p>}
    </div>
  )
}
