import { postData  , deleteData , getData } from './index';

import {
  ERROR_FORM,
  POST_USER,
  POST_QUOTE,
  GET_CALENDAR,
  POST_CALENDAR,
  POST_CASE,
  POST_ENTITY
} from "./types"


export async function postUserAction(dispatch , data ) {
  let userUrl = "/auth/register" ;
  var user_id;

  await postData( POST_USER , ERROR_FORM ,  userUrl , dispatch, data ).then((response)=> {
    localStorage.setItem("token" , response.token);
    user_id = response.user._id
  });
  console.log("user created", user_id);

  let caseUrl = "/case";
  const caseData = {
    name: `Dossier ${data?.company?.name}`,
    origin: 'Autre',
    users: [user_id]
  }
  var case_id;
  await postData( POST_CASE , ERROR_FORM ,  caseUrl , dispatch, caseData , true ).then((response)=>{
    case_id = response.case._id
  });

  console.log("case created", case_id);

  let entityUrl = "/entity";
  const entityData = {
    name: data.company?.name,
    siret: data.company?.siret,
    email: data.email,
    tel: data.profile?.tel,
    firstName: data.profile?.firstName,
    lastName: data.profile?.lastName,
    adress: data?.profile?.adress,
    activity: data?.profile?.activity,
    files: [],
    case: case_id
  }
  var entity_id;
  await postData( POST_CASE , ERROR_FORM ,  entityUrl , dispatch, entityData , true ).then((response)=>{
    entity_id = response.entity._id
  });

  console.log("entity created", entity_id);

  dispatch({
    type : POST_ENTITY,
    payload : entity_id
  });

};

export async function postQuoteAction(dispatch , data ) {
  let url = "/quote" ;
  var quote;

  await postData( POST_QUOTE , ERROR_FORM ,  url , dispatch, data , true ).then((response)=>{
    quote = response.quote
  });

  dispatch({
    type : POST_QUOTE,
    payload : quote
  });

};

export async function deleteQuoteAction(dispatch , id ) {
  let url = "/quote/" + id ;
  var quote;

  await deleteData( ERROR_FORM , url, dispatch , true ).then((response)=>{
    quote = response
  });

  if (quote) {
    dispatch({
      type : POST_QUOTE,
      payload : null
    });
  }

};

export async function getCalendarAction(dispatch) {
  let url = "/calendar";
  var calendar;

  await getData(ERROR_FORM, url, dispatch ).then((response)=>{
    calendar = response.calendar
  });

  dispatch({
    type : GET_CALENDAR,
    payload : calendar
  });

};

export async function postCalendarAction(dispatch , data , date ) {
  let url = "/calendar" ;
  var meet;

  await postData( POST_CALENDAR , ERROR_FORM ,  url , dispatch, data , true ).then((response)=>{
    meet = response
  });

  dispatch({
    type : POST_CALENDAR,
    payload : {
      date : date,
      message: meet.message
    }
  });

};


