import React from 'react';
import { format } from 'date-fns';

import { ADMIN_URL } from '../../../actions/index'; 

import { useGlobalContext } from '../../../context/GlobalContext';
import Button from '../../partials/Button/Button';

import quoteImg from '../../../assets/images/quote.png';
import styles from './End.module.scss'

export default function End() {

  const context  = useGlobalContext()[0];
  const { meetDate } = context.form ;

  const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']

  return (
    <div className={`page-content`}>
      <div className="content">
        <div className="row">

          <div className="col-2">
            <div className={styles.container}>
              <div className={`${styles.message} ${styles.anim}`}>
                <img className="mobile" src={quoteImg} alt="home" /> 
                <h1>Merci pour votre confiance !</h1>
                <div className={styles.connect}>
                  
                  {meetDate &&
                    <>
                      <label>Vous serez recontacté </label><br/>
                      <span className="text-primary">
                        le {days[meetDate.getDay()]} {format(new Date(meetDate) , 'dd/MM/yyyy')} à {format(new Date(meetDate), 'HH:mm')}
                      </span>
                    </>
                  }
                  
                  <a href={ADMIN_URL}>
                    <Button primary >
                      Me connecter
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-2 illu">
            <img className="anim-bottom" src={quoteImg} alt="end" />
          </div>

        </div>
      </div>
    </div>
  )
}