import React from 'react';
import { FiLoader } from 'react-icons/fi';
import styles from './Loader.module.scss';

export default function Loader({ isLoading , size , color }) {
  return (
    <div className={`${styles.loader} ${isLoading ? styles['is-loading'] : ""}`}>
      <FiLoader size={size ? size : 30 } color={color ? color: "#000000" }/>
    </div>
  )
}