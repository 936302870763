import React, { useEffect , useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../context/GlobalContext';

import home from '../../../assets/images/home.png';
import Step from '../../partials/Step/Step';

export default function Step2() {

  const [ context , dispatch ] = useGlobalContext();
  const { quoteForm , countSteps } = context.form;
  const [ activeStep , setActiveStep ] = useState();

  const { activity } = useParams();

  const step = {
    prev: `/step1/${activity}`,
  } 

  let questions = [
    {
      question: "Votre nombre de biens",
      key: "propertyNumber",
      type: "number",
      activities : ['location'],
    },
    {
      question: "Vous appliquez la TVA ?",
      key: "tva",
      type: "list",
      activities : ['location' , 'liberal' , 'freelance' , 'artisan' , 'entrepreneur'],
      options: [
        { label: "Oui", value: "yes" },
        { label: "Non", value: "no"},
        { label: "Je ne sais pas", value: "unknown"},
      ]
    },
    {
      question: "Vous réfléchissez à changer de statuts ?",
      key: "statusSwitch",
      type: "list",
      activities : [ 'entrepreneur' ],
      options: [
        { label: "Oui", value: "yes" },
        { label: "Non", value: "no"},
        { label: "Je ne sais pas", value: "unknown"},
      ]
    },
    {
      question: "Des salariés ?",
      key: "employee",
      type: "number",
      activities : ['liberal' , 'freelance' , 'artisan' ],
    },
  ];

  useEffect(() => {
    let totalSteps = quoteForm.turnoverValue? 6 : 5;

    if (activity) {

      switch (activity) {

        case 'location':
          step.title = "Activité de location immobilière" ;
          step.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in laoreet diam. Maecenas sollicitudin enim vitae massa lobortis.";
          step.questions = questions.filter(q => q.activities.find( activity => activity === 'location'));
          step.totalSteps = 4 + step.questions.length ;
          return setActiveStep(step);

        case 'liberal':
          step.title = "Activité libérale" ;
          step.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in laoreet diam. Maecenas sollicitudin enim vitae massa lobortis.";
          step.questions = questions.filter(q => q.activities.find( activity => activity === 'liberal'));
          step.totalSteps = totalSteps + step.questions.length ;
          return setActiveStep(step);

        case 'freelance':
          step.title = "Activité indépendante";
          step.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in laoreet diam. Maecenas sollicitudin enim vitae massa lobortis.";
          step.questions = questions.filter(q => q.activities.find( activity => activity === 'freelance'));
          step.totalSteps = totalSteps + step.questions.length ;
          return setActiveStep(step);

        case 'artisan':
          step.title = "Activité artisanale";
          step.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in laoreet diam. Maecenas sollicitudin enim vitae massa lobortis.";
          step.questions = questions.filter(q => q.activities.find( activity => activity === 'artisan'));
          step.totalSteps = totalSteps + step.questions.length ;
          return setActiveStep(step);

        case 'entrepreneur':
          step.title = "Activité entrepreneur";
          step.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in laoreet diam. Maecenas sollicitudin enim vitae massa lobortis.";
          step.questions = questions.filter(q => q.activities.find( activity => activity === 'entrepreneur'));
          step.totalSteps = totalSteps + step.questions.length ;
          return setActiveStep(step);
      
        default:
          return null;
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let updatedStep = Object.assign({} , activeStep );

    if (updatedStep) {
      if (quoteForm.tva === "yes") {
        updatedStep.totalSteps = updatedStep.totalSteps + 1;
        let index = activity === 'location' ? 2 : 1 ;
        updatedStep.questions?.splice(index , 0 , {
          question: "Quelle est sa périodicité ?",
          key: "tvaPeriodicity",
          type: "list",
          activities : ['location' , 'liberal' , 'freelance' , 'artisan' , 'entrepreneur' ],
          options: [
            { label: "Annuelle", value: "annual" },
            { label: "Trimestrielle", value: "quarterly"},
            { label: "Mensuelle", value: "monthly"},
            { label: "Je ne sais pas", value: "unknown"},
          ]
        });
        setActiveStep(updatedStep);
      } else if (updatedStep.questions && updatedStep.questions.find(q => q.key === "tvaPeriodicity")) {
        updatedStep.totalSteps = updatedStep.totalSteps - 1;
        updatedStep.questions = updatedStep.questions.filter(q => q.key !== "tvaPeriodicity");
        if (quoteForm.tvaPeriodicity) {
          let updatedForm = Object.assign({} , quoteForm );
          delete updatedForm.tvaPeriodicity
          dispatch({
            type: 'SET_FORM',
            payload: updatedForm
          });
          dispatch({
            type: 'SET_COUNT_STEPS',
            payload: countSteps - 1
          });
        };
        setActiveStep(updatedStep);
      }
    }
    //eslint-disable-next-line
  }, [quoteForm?.tva])


  return (
    <div className={`page-content`}>
      <div className="content">
        <div className="row">

          <div className="col-2">
            {activeStep &&
              <Step
                step={activeStep}
                isEnd={true}
                next={`/valid/${activity}`}
                />
            }
          </div>

          <div className="col-2 illu">
            <img src={home} alt="home" />
          </div>

        </div>
      </div>
    </div>
  )
}