import React , { useEffect , useState } from 'react';
import { format } from 'date-fns';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';
import styles from './SelectMeet.module.scss'; 

export default function SelectMeet({ calendar , selectedMeet , setSeletedMeet , days }) {

  const [ calendarData , setCalendarData ] = useState();
  const [ offset , setOffset ] = useState(0);

  useEffect(() => {
    if (calendar.length > 0 ) {
      let meetDays = [];
      calendar.forEach(meet => {
        let date = new Date(meet.start.dateTime);
        if (!meetDays.find(day => day.toDateString() === date.toDateString())) {
          meetDays.push(date)
        };
      });
      
      meetDays = meetDays.sort((a ,b) => a - b )

      let dateArray = [];
      let currentDate = meetDays[0];
      if (currentDate.getDay() > 1) {
        currentDate = currentDate.removeDays(currentDate.getDay() - 1);
      }

      while (currentDate < meetDays[meetDays.length - 1].addDays(1)) {
        if (currentDate.getDay() !== 6 && currentDate.getDay() !== 0 ) {
          dateArray.push(currentDate);
        }
        currentDate = currentDate.addDays(1);
      }

      let daysList = [];

      dateArray.forEach(date => {
        let sameDays = calendar.filter(item => new Date(item.start.dateTime).toDateString() === date.toDateString()).sort(( a , b ) => new Date(a.start.dateTime) - new Date(b.start.dateTime))
         daysList.push({
            date: date,
            dayMeets: sameDays.length ? sameDays.map( item => ({ id: item.id , hour: formatHour(new Date(item.start.dateTime)) , date: new Date(item.start.dateTime) , user: item.user })) : null
         })
      });

      setCalendarData(daysList)

    }

  },[calendar]);

  function formatHour(date) {
    return format(date, 'HH:mm');
  }

  // eslint-disable-next-line no-extend-native
  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  // eslint-disable-next-line no-extend-native
  Date.prototype.removeDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() - days);
    return date;
  }

  function handleChangeOffset(dir) {
    let result = offset;
    if (dir === "next" && Math.abs(result) < (calendarData.length - 1) * 102) {
      result -= 102;
    } else if (dir === "prev" && result !== 0) {
      result += 102
    }
    setOffset(result)
  }

  return (
    <>
    {calendarData &&
      <>
      <div className={`${styles.container} ${styles.anim}`}>
        {calendarData.map((day , i ) => {
          return (
            <div key={'day' + i}
              style={{ transform: `translateX(${offset}px)`}}
              className={styles.day}>
              <p>
                <span className="bold">{days[day.date.getDay()]}</span><br/>
                {format(day.date,'dd/MM')}
              </p>
              {day.dayMeets && day.dayMeets.map(meet => {
                return (
                  <button
                    key={meet.id}
                    onClick={() => setSeletedMeet(meet)}
                    className={`${styles.btn} ${selectedMeet && selectedMeet.id === meet.id ? styles['is-active']:''}`}>
                    {meet.hour}
                  </button>
                )
              })}
            </div>
          )
        })}
      </div>
      <div className={styles.arrows}>
        <button
          onClick={() => handleChangeOffset("prev")}
          >
          <HiArrowLeft size={24} />
        </button>
        <button
          onClick={() => handleChangeOffset("next")}
          >
          <HiArrowRight size={24} />
        </button>
      </div>
      </>
      }
    </>
  )
}
