const dark = '#192243'

export const formSelect = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    marginTop: 10,
    marginBottom: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 36,
    fontSize: 14,
    top: '48%',
    padding: "0px 8px"
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#FFFFFF",
    top: '48%'
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 36,
  }),
  indicatorSeparator:(provided, state) => ({
    ...provided,
    display: 'none'
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#192243",
  }),
  control: (provided, state) => {
     return ({
    ...provided,
    backgroundColor: dark,
    minHeight: 25,
    borderRadius: 5,
    margin: 0,
    // boxShadow: "0px 10px 28px rgb(224, 224, 224)",
    border: state.isFocused || state.isSelected ? `1px solid ${dark}` : `1px solid ${dark}`,
    '&:hover': {
      border: state.isFocused || state.isSelected ? `1px solid ${dark}` : `1px solid ${dark}`,
    }
  })},
  menu: (provided, state) => ({
    ...provided,
    marginTop: -2,
  }),
  menuList: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    fontSize: 14,
    color: state.isFocused || state.isDisabled || state.isSelected ? '#FFFFFF' : '#FFFFFF' ,
    backgroundColor: state.isFocused || state.isDisabled || state.isSelected ? dark : dark,
  })
}