import React , { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { HiArrowLeft ,  HiArrowRight  } from 'react-icons/hi';

import { useGlobalContext } from '../../../context/GlobalContext';

import Button from '../Button/Button';
import Question from '../Question/Question';
import styles from './Step.module.scss';

export default function Step({ step , next  , isEnd }) {

  const [ context , dispatch ] = useGlobalContext();
  const  history = useHistory();
  const { quoteForm , countSteps } = context.form;

  const [ activeIndex , setActiveIndex ] = useState(countSteps >= step.totalSteps ? step.questions.length : 0 );

  function handleChangeStep( key , value , index ) {
    if (index >= activeIndex) {
      setActiveIndex(index);
      dispatch({
        type: 'SET_COUNT_STEPS',
        payload: 1 + countSteps
      })
    }
    let updatedForm = {...quoteForm}
    updatedForm[key] = value
    dispatch({
      type: 'SET_FORM',
      payload: updatedForm
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.anim}>
        <h2>{step.title}</h2>
        {step.questions?.filter((item , i )  => i <= activeIndex ).map((item ,i ) => <Question
            key={`question-${i}`}
            activeIndex={activeIndex}
            question={item.question}
            type={item.type}
            value={quoteForm && quoteForm[item.key]}
            options={item.options}
            handleChange={(val) => handleChangeStep( item.key , val , i + 1 )}
            />)}
      </div>

      <div className={styles.buttons}>
        <Link to={step.prev}>
          <Button
            primary
          >
            <div className={styles.icon}><HiArrowLeft size={18} /> PRÉCÉDENT </div>
          </Button>
        </Link>
        <Button
          disabled={activeIndex + 1 <= step.questions?.length ? true : false }
          onClick={() => history.push(next)}
          primary
         >
        {isEnd ?
          <div className={styles.icon}>
            <span className="desktop">FINALISER MON DEVIS</span>
            <span className="mobile">FINALISER</span>
          </div>
          :
            <div className={styles.icon}>SUIVANT  <HiArrowRight size={18} /></div>  
          }
        </Button>
      </div>

    </div>
  )
}
