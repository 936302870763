import {
  ERROR_FORM,
  MESSAGE_FORM,
  SET_FORM,
  SET_COUNT_STEPS,
  SET_USER_FORM,
  POST_ENTITY,
  POST_USER,
  POST_QUOTE,
  GET_CALENDAR,
  POST_CALENDAR
} from "../actions/types";

const initialState = {
  error: null,
  message: null,
  quoteForm: {},
  quote : null,
  userForm: {},
  countSteps: 0,
  user: null,
  calendar: null,
  meetDate: null
};

export default function formReducer(state = initialState, action) {
  switch (action.type) {

    case POST_CALENDAR:
      return {...state , meetDate: action.payload.date}

    case GET_CALENDAR :
      return {...state , calendar: action.payload}

    case SET_COUNT_STEPS :
        return {...state , countSteps: action.payload}

    case POST_USER :
      return {...state , user: action.payload }

    case POST_ENTITY :
      return {...state , quoteForm: {...state.quoteForm , client: action.payload }}
    
    case POST_QUOTE :
      return {...state , quote: action.payload}

    case SET_USER_FORM :
      return {...state , userForm: action.payload}

    case SET_FORM :
      return {...state , quoteForm: action.payload}

    case MESSAGE_FORM:
      return {...state , message: action.payload}

    case ERROR_FORM:
      return {...state , error: action.payload}
    default:
      return {...state};
  }
}