import React from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';

import homeImg from '../../../assets/images/home.png';
import quoteImg from '../../../assets/images/quote.png';
import UserForm from '../../partials/UserForm/UserForm';

export default function User() {
  const context = useGlobalContext()[0];
  const { quote } = context.form;

  return (
    <div className={`page-content`}>
      <div className="content">
        <div className="row">

          <div className="col-2">
            <UserForm />
          </div>

          <div className="col-2 illu">
            {quote ? <img className="anim-bottom" src={quoteImg} alt="home" /> :  <img src={homeImg} alt="home" /> }
          </div>

        </div>
      </div>
    </div>
  )
}