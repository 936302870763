import React from 'react';
import CustomSelect from '../Form/Select/CustomSelect';
import Input from '../Form/Input/Input';
import styles from './Question.module.scss';

export default function Question({ question , options , handleChange , type,  value }) {

  return (
    <div className={styles.container}>
      <label>{question}</label>
      {type === "list" &&
        <div className={styles.options}>
          {options.map(( item , i ) =>
            <button
              key={`option${i}`}
              className={`${styles.btn} ${item.value === value ? styles['is-active'] : ""}`}
              onClick={() => handleChange(item.value)}
              >{item.label}
            </button>
          )}
        </div>  
      }
      {type ===  "select" &&
        <div className={styles.select}>
          <CustomSelect
            options={options}
            defaultValue={options.find(opt => opt.value === value)}
            handleChange={(opt) => handleChange(opt.value)}
            />
        </div>  
      }
      {question === "Des salariés ?" && 
        <div className={`${styles.options}`}>
          <button
            className={`${styles.btn} ${styles.employee} ${value === 0 ? styles['is-active'] : ""}`}
            onClick={() => handleChange(0)}
            >
            aucun
          </button>
          <div className={styles.input}>
            <Input
              type="number"
              placehloder="Nombre de salariés"
              min="0"
              defaultValue={null}
              value={value ? value : null }
              onChange={(val) => handleChange(parseFloat(val))} />
          </div>
        </div>
      }
      {type === "number" && question !== "Des salariés ?" &&
        <div className={styles.input}>
          <Input
            type="number"
            min="0"
            defaultValue={value ? value : null }
            value={value ? value : null }
            onChange={(val) => handleChange(parseFloat(val))} />
        </div>  
      }
      {question === "Votre situation..." && value === "launched" && <p>Votre entreprise est déjà créée</p>}
      {question === "Votre situation..." && value === "launching" && <p>Votre entreprise n’existe pas encore</p>}
    </div>
  )
}
