import React, { useState , useEffect } from 'react';
import { Link , useParams , useHistory } from 'react-router-dom';
import { useForm , Controller } from "react-hook-form";

import { HiArrowLeft ,  HiCheck } from 'react-icons/hi';

import { useGlobalContext } from '../../../context/GlobalContext';
import { postUserAction , postQuoteAction , deleteQuoteAction } from '../../../actions/formActions';

import Input from '../Form/Input/Input';
import Button from '../Button/Button';
import CustomSelect from '../../partials/Form/Select/CustomSelect';
import Loader from '../../partials/Loader/Loader';
import quoteImg from '../../../assets/images/quote.png';

import styles from './UserForm.module.scss';

export default function UserForm() {


  const { activity } = useParams();
  const history = useHistory();
  const { handleSubmit, register, errors , control , watch , setValue ,getValues } = useForm();

  const [ context , dispatch ] = useGlobalContext();
  const { quoteForm , userForm , quote } = context.form;

  const [ isSubmit , setIsSubmit ] = useState(quoteForm.client ? true : false);
  const [ displayActivityInput , setDisplayActivityInput ] = useState(false);

  const genderOptions = [{ label: "Monsieur" ,value: "male"} , { label: "Madame" , value: 'female'}]

  const liberalOptions = ["MÉDICAL ET PARAMÉDICAL", "JURIDIQUE ET JUDICIAIRE", "ARTISTIQUE", "FORMATION ET CONSEIL", "ARCHITECTURE", "ASSURANCE", "AGENT COMMERCIAL", "AUTRE - impossible de préciser"].map(d => ({ value: d ,label: d}))
  const freelanceOptions = ["CONSULTING / CONSEILS", "INFORMATIQUE/DESIGNER", "VTC", "COURTAGE", "AGENCE IMMOBILIÈRE", "AUTRE - impossible de préciser"].map(d => ({ value: d ,label: d}))


  useEffect(() => {
    if (activity === "liberal" || activity === "artisan" || activity === "freelance" || activity === "entrepreneur") {
      register('profile.activity');
    }
    // eslint-disable-next-line
  }, [activity])

  useEffect(() => {
    if (isSubmit && quoteForm.client) {
      postQuoteAction(dispatch , quoteForm);
    }
    // eslint-disable-next-line
  }, [quoteForm , isSubmit ]);

  const backToForm = () => {
    setIsSubmit(false);
    deleteQuoteAction(dispatch , quote._id)
  }

  useEffect(() => {
    if (watch('profile.activity') === "AUTRE - impossible de préciser") {
      setDisplayActivityInput(true);
    }
    // eslint-disable-next-line
  },[watch('profile.activity')])

  useEffect(() => {
    if (context.form.error?.data?.error === "That email address is already in use.") {
      history.push("/account")
    }
    // eslint-disable-next-line
  }, [context.form.error])

  const submitUser = (values) => {
    setIsSubmit(true);
    if (!values.profile.activity) values.profile.activity = 'AUTRE - à préciser';
    if (!quoteForm.client) {
      postUserAction(dispatch , values );
      dispatch({
        type: 'SET_USER_FORM',
        payload: values
      });
    }
  }

  const saveUser = () => {
    const values = getValues();
    dispatch({
      type: 'SET_USER_FORM',
      payload: values
    })
  }

  const handleChangeCode  = (val) => {
    let updatedForm = Object.assign({} ,quoteForm);
    updatedForm.code = val
    dispatch({
      type: 'SET_FORM',
      payload: updatedForm
    });
  }

  return (
    <>
      {!isSubmit ?
      <form onSubmit={handleSubmit(submitUser)} className={styles.container}>
        <div className={styles.anim}>

          <div>
            <h2>Finalisation de votre devis</h2>
          </div>

          <div className={`row ${styles.row}`}>
            <div className="col-2">
              <Input
                label={"Nom"}
                name={"profile.lastName"}
                defaultValue={userForm.profile && userForm.profile.lastName}
                register={register({
                  required: "Compléter le nom",
                })}
                withErrors={true}
                error={errors.profile && errors.profile.lastName && errors.profile.lastName.message}
              />
            </div>
            <div className="col-2">
              <Input
                label={"Prénom"}
                name={"profile.firstName"}
                defaultValue={userForm.profile && userForm.profile.firstName}
                register={register({
                  required: "Compléter le prénom",
                })}
                withErrors={true}
                error={errors.profile && errors.profile.firstName && errors.profile.firstName.message}
              />
            </div>
          </div>

          <div className={`row ${styles.row}`}>
            <div className="col-2">
              <Input
                label={"Mail"}
                name="email"
                defaultValue={userForm.email && userForm.email}
                register={register({
                  required: "Compléter votre email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Adresse mail invalide"
                  }
                })}
                withErrors={true}
                error={errors.email && errors.email.message}
                />
            </div>
            <div className="col-2">
              <Input
                label={"Téléphone portable"}
                name={"profile.tel"}
                defaultValue={userForm.profile && userForm.profile.tel}
                register={register({
                  required: "Compléter le numéro de téléphone",
                })}
                withErrors={true}
                error={errors.profile && errors.profile.tel && errors.profile.tel.message}
              />
            </div>
          </div>

          <div className={`row ${styles.row}`}>
            <div className="col-2">
              <Controller
                name={"profile.gender"}
                rules={{ required: "le genre est requis" }}
                control={control}
                defaultValue={userForm.profile && userForm.profile.gender && genderOptions.find(item => item.value ===  userForm.profile && userForm.profile.gender) ? genderOptions.find(item => item.value ===  userForm.profile && userForm.profile.gender) : null}
                render={(props) =>
                  <CustomSelect
                    label={"Monsieur / Madame"}
                    defaultValue={userForm.profile && userForm.profile.gender && genderOptions.find(item => item.value ===  userForm.profile && userForm.profile.gender) ? genderOptions.find(item => item.value ===  userForm.profile && userForm.profile.gender) : null}
                    handleChange={(opt) => setValue('profile.gender' , opt.value)}
                    options={genderOptions}
                    withErrors={true}
                    error={errors.profile && errors.profile.gender && errors.profile.gender.message}
                    />
                }
              />
            </div>
            <div className="col-2">
            
            </div>
          </div>

          <div className={`row ${styles.row}`}>
            <div className="col-2">
              <Input
                label={"Mot de passe"}
                name="password"
                type={"password"}
                defaultValue={userForm.password && userForm.password}
                register={register({
                  required: "Compléter votre mot de passe",
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/i,
                    message: "6 caractères et 1 chiffre avec Majuscule et minuscule"
                  }
                })}
                withErrors={true}
                error={errors.password && errors.password.message}
                />
            </div>
            <div className="col-2">
            <Input
                label={"Confirmer le mot de passe"}
                name="passwordConfirm"
                type={"password"}
                defaultValue={userForm.passwordConfirm && userForm.passwordConfirm}
                register={register({
                  required: "Confirmer votre mot de passe",
                  validate: (value) => value === watch('password') || "Le mot de passe ne corresponds pas"
                })}
                withErrors={true}
                error={errors.passwordConfirm && errors.passwordConfirm.message}
                />
            </div>
          </div>

          { activity === "artisan" ||  activity === "entrepreneur" || displayActivityInput ?
            <div className={`row ${styles.row}`}>
              <div className="col-1">
                <Input
                  label={"Précision de l'activité"}
                  name={"profile.activity"}
                  defaultValue={userForm.profile && userForm.profile.activity}
                  register={register({
                    required: "l'activité est requise",
                  })}
                  withErrors={true}
                  error={errors.profile && errors.profile.activity && errors.profile.activity.message}
                />
              </div>
            </div>
            :
            null
          }

          {activity === "liberal" && !displayActivityInput ?
            <Controller
              name={"profile.activity"}
              rules={{ required: "l'activité est requise" }}
              control={control}
              defaultValue={userForm.profile && userForm.profile.activity && liberalOptions.find(item => item.value ===  userForm.profile && userForm.profile.activity) ? liberalOptions.find(item => item.value ===  userForm.profile && userForm.profile.activity) : null}
              render={(props) =>
                <CustomSelect
                  label={"Précision de l'activité"}
                  defaultValue={userForm.profile && userForm.profile.activity && liberalOptions.find(item => item.value ===  userForm.profile && userForm.profile.activity) ? liberalOptions.find(item => item.value ===  userForm.profile && userForm.profile.activity) : null}
                  handleChange={(opt) => setValue('profile.activity' , opt.value)}
                  options={liberalOptions}
                  withErrors={true}
                  error={errors.profile && errors.profile.activity && errors.profile.activity.message}
                  />
              }
            />
            :
            <></>
          }

          {activity === 'freelance' && !displayActivityInput &&
            <Controller
              name={"profile.activity"}
              rules={{ required: "l'activié est requise" }}
              control={control}
              defaultValue={userForm.profile && userForm.profile.activity && freelanceOptions.find(item => item.value ===  userForm.profile && userForm.profile.activity) ? freelanceOptions.find(item => item.value ===  userForm.profile && userForm.profile.activity) : null}
              render={(props) =>
                <CustomSelect
                  label={"Précision de l'activité"}
                  defaultValue={userForm.profile && userForm.profile.activity && freelanceOptions.find(item => item.value ===  userForm.profile && userForm.profile.activity) ? freelanceOptions.find(item => item.value ===  userForm.profile && userForm.profile.activity) : null}
                  handleChange={(opt) => setValue('profile.activity' , opt.value)}
                  options={freelanceOptions}
                  withErrors={true}
                  error={errors.profile && errors.profile.activity && errors.profile.activity.message}
                  />
              }
            />
          }

          <div className={`row ${styles.row}`}>
            <div className="col-2">
              <Input
                label={"Nom de votre société"}
                name={"company.name"}
                register={register}
                defaultValue={userForm.company && userForm.company.name}
                withErrors={true}
              />
            </div>
            <div className="col-2">
              <Input
                label={"N° Siret de votre société"}
                name={"company.siret"}
                register={register}
                defaultValue={userForm.company && userForm.company.siret}
                withErrors={true}
              />
            </div>
          </div>

          <div className={`row ${styles.row}`}>
            <div className="col-1">
              <Input
                label={"Adresse de la société"}
                name={"profile.adress.street"}
                register={register}
                defaultValue={userForm?.profile?.adress?.street}
                withErrors={true}
              />
            </div>
          </div>

          <div className={`row ${styles.row}`}>
            <div className="col-2">
              <Input
                label={"Ville"}
                name={"profile.adress.city"}
                register={register}
                defaultValue={userForm?.profile?.adress?.city}
                withErrors={true}
              />
            </div>
            <div className="col-2">
              <Input
                label={"Code postal"}
                name={"profile.adress.cp"}
                register={register}
                defaultValue={userForm?.profile?.adress?.cp}
                withErrors={true}
              />
            </div>
          </div>

          <div className={`row ${styles.row}`}>
            <div className="col-2">
              <Input
                label={"Pays"}
                name={"profile.adress.country"}
                register={register}
                defaultValue={userForm?.profile?.adress?.country}
                withErrors={true}
              />
            </div>
          </div>

          <div className={`row ${styles.row} ${styles.code}`}>
            <div className="col-2">
              <Input
                label="Code promo ?"
                value={quoteForm?.code && quoteForm?.code}
                className={styles.input}
                onChange={val => handleChangeCode(val)}
                />
            </div>
          </div>

        </div>

        <div className={styles.buttons}>
          <Link to={quoteForm.activity !== "other" ? `/step2/${quoteForm.activity}` : '/activity'}>
            <Button
              primary
            >
              <div className={styles.icon}><HiArrowLeft size={18} /> PRÉCÉDENT </div>
            </Button>
          </Link>
          <Button
            type={"submit"}
            onClick={() => saveUser()}
            disabled={false}
            primary
          >
            <div className={styles.icon}> Valider  <HiCheck size={18} /></div>
          </Button>
        </div>
    
      </form>
      :
      <div className={styles.container}>
        {!quote &&
          <div className={styles.loader}>
            <Loader size={40} isLoading={true} color={'#4AC3C9'}/>
          </div>
        }
        {quote &&  
          <div className={`${styles.message} ${styles.anim}`}>
            <img className="mobile" src={quoteImg} alt="home" /> 
            <h1>Merci pour votre confiance !</h1>
            <div>
                <label>Estimation de votre offre</label>
                {quote.amountHT > 0 &&
                  <>
                    <h2>{`${quote.amountHT} €`} HT mensuel</h2>
                    {quote?.employee > 0 && <p>*Honoraires hors gestion des salariés</p>}
                  </>
                }
                {quote.amountHT === 0 &&
                  <h2>
                    Vos réponses ne nous permettent pas une estimation précise,<br/>
                    nous la définirons lors de notre prochain échange.
                  </h2>
                }
              <Link to={`/meet/${quoteForm.activity}`}>
                <Button primary >
                  PRENDRE RENDEZ-VOUS
                </Button>
              </Link>
              <div className={styles.back}>
                <button
                  onClick={() => backToForm()}
                  >
                  Revenir à mon parcours
                </button>
              </div>
            </div>
          </div>
          }
      </div>
    }
    </>
  )
}
